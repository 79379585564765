// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-desperately-seeking-jsx": () => import("./../../../src/pages/desperately-seeking.jsx" /* webpackChunkName: "component---src-pages-desperately-seeking-jsx" */),
  "component---src-pages-donate-real-estate-jsx": () => import("./../../../src/pages/donate-real-estate.jsx" /* webpackChunkName: "component---src-pages-donate-real-estate-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-jsx": () => import("./../../../src/pages/jobs.jsx" /* webpackChunkName: "component---src-pages-jobs-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-mixed-media-jsx": () => import("./../../../src/pages/mixed-media.jsx" /* webpackChunkName: "component---src-pages-mixed-media-jsx" */),
  "component---src-pages-order-online-jsx": () => import("./../../../src/pages/order-online.jsx" /* webpackChunkName: "component---src-pages-order-online-jsx" */),
  "component---src-pages-plant-donations-jsx": () => import("./../../../src/pages/plant-donations.jsx" /* webpackChunkName: "component---src-pages-plant-donations-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-volunteer-jsx": () => import("./../../../src/pages/volunteer.jsx" /* webpackChunkName: "component---src-pages-volunteer-jsx" */),
  "component---src-pages-wholesale-jsx": () => import("./../../../src/pages/wholesale.jsx" /* webpackChunkName: "component---src-pages-wholesale-jsx" */)
}

